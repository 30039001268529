import React from "react"
import Layout from "../../components/Layout"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import SEO from "../../components/SEO"
import { useLanguage } from "../../contexts/LanguageContext"
const LegalDepartment = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <Layout>
      <SEO title="Kontakt" />
      <div className="img-wrapper img-wrapper-about">
        <Image
          Tag="div"
          fluid={fluid}
          className=" bcg bcg-about bcg-contact "
        />
        {selectedLanguage === "EN" ? (
          <h2 className="about-header visibility-filter contact-header ">
            We are available for all your inquiries!
          </h2>
        ) : (
          <h2 className="about-header visibility-filter contact-header ">
            Za sve Vaše upite stojimo na raspolaganju!
          </h2>
        )}
        {selectedLanguage === "EN" ? (
          <h2 className="about-header mobile-visible">Contact</h2>
        ) : (
          <h2 className="about-header mobile-visible">Kontakt</h2>
        )}
      </div>
      <div className="about-content">
        {selectedLanguage === "EN" ? (
          <div className="contact-content-wrapper">
            <h3 className="contact-us-header contact-us-header-pravniposlovi">
              SECTOR OF LEGAL, PERSONNEL AND GENERAL AFFAIRS
            </h3>
            <ul className="contact-us-list">
              <li className="contact-list-name">Maja Franković</li>
              <li>
                <address>
                  e-mail:{" "}
                  <a href="mailto:  maja.frankovic@osijek-koteks.hr">
                    maja.frankovic@osijek-koteks.hr
                  </a>{" "}
                </address>
              </li>
              <li>
                <address>
                  {" "}
                  Human resources:{" "}
                  <a href="mailto:  zvonimir.skoric@osijek-koteks.hr">
                    zvonimir.skoric@osijek-koteks.hr
                  </a>
                </address>
              </li>
              <li>
                <address>
                  Safety at work:{" "}
                  <a href="mailto:  zvonimir.skoric@osijek-koteks.hr">
                    darko.jukic@osijek-koteks.hr
                  </a>
                </address>
              </li>
              <li>
                <address>
                  IT support:
                  <a href="mailto: it@osijek-koteks.hr"> it@osijek-koteks.hr</a>
                </address>
              </li>
              <li>
                <address>
                  Personal data protection officer:
                  <a href="mailto: zop@osijek-koteks.hr">
                    {" "}
                    zop@osijek-koteks.hr
                  </a>
                </address>
              </li>
            </ul>
          </div>
        ) : (
          <div className="contact-content-wrapper">
            <h3 className="contact-us-header contact-us-header-pravniposlovi">
              SEKTOR PRAVNIH, KADROVSKIH I OPĆIH POSLOVA
            </h3>
            <ul className="contact-us-list">
              <li className="contact-list-name">Maja Franković</li>
              <li>
                <address>
                  e-mail:{" "}
                  <a href="mailto:  maja.frankovic@osijek-koteks.hr">
                    maja.frankovic@osijek-koteks.hr
                  </a>{" "}
                </address>
              </li>
              <li>
                <address>
                  {" "}
                  Služba za ljudske resurse:{" "}
                  <a href="mailto:  zvonimir.skoric@osijek-koteks.hr">
                    zvonimir.skoric@osijek-koteks.hr
                  </a>
                </address>
              </li>
              <li>
                <address>
                  Služba sigurnost na radu:{" "}
                  <a href="mailto:  zvonimir.skoric@osijek-koteks.hr">
                    darko.jukic@osijek-koteks.hr
                  </a>
                </address>
              </li>
              <li>
                <address>
                  Služba za informatičku potporu:
                  <a href="mailto: it@osijek-koteks.hr"> it@osijek-koteks.hr</a>
                </address>
              </li>
              <li>
                <address>
                  Službenik za zaštitu osobnih podataka:
                  <a href="mailto: zop@osijek-koteks.hr">
                    {" "}
                    zop@osijek-koteks.hr
                  </a>
                </address>
              </li>
            </ul>
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "imovina-na-prodaju-sl.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default LegalDepartment
